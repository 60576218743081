<template>
  <div class="detail" v-loading="loading">
    <div class="list-content" v-if="detail">
      <el-card class="no-border" shadow="never">
        <div class="card-title-no-border">
          <label>心得内容</label>
        </div>
        <el-divider></el-divider>
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <div>会员名称 : {{detail.user ? detail.user.nickname : '-'}}</div>
            </el-col>
            <el-col :span="8">
              <div>架构/会内职务 :
                {{detail.user && detail.user.organization ? (detail.user.organization.title_name ? detail.user.organization.title_name : '-') : '-'}}
              </div>
            </el-col>
          </el-row>
          <div class="card-table">
            <div class="table-title">心得内容</div>
            <div class="table-content" @mouseenter="showTextarea = true" @mouseleave="showTextarea = false">
              <div v-show="!showTextarea">{{detail.content}}</div>
              <div v-show="showTextarea">
                <el-input @blur="showTextarea = false" type="textarea" v-model="detail.content"></el-input>
              </div>
              <div class="total-count text-right">{{detail.content.length}}</div>
            </div>
          </div>
          <div style="margin-top: 20px">
            <media-wall v-model="detail.res" :width="150" :height="96" :borderRadius="4"
              :limit="9 - detail.res.length" addText="上传图片/视频" :UploadType="['video','image']"></media-wall>
          </div>

        </div>
      </el-card>
    </div>
    <div style="margin-top: 20px" class="list-content" v-if="detail">
      <el-card class="no-border" shadow="never">
        <div class="card-title-no-border">
          <label>内容数据</label>
        </div>
        <el-divider></el-divider>
        <div class="card-content">
          <el-table :data="tableData" border stripe class="thead-light">
            <el-table-column prop="title"></el-table-column>
            <el-table-column prop="origin" label="用户数据"></el-table-column>
            <el-table-column prop="virtual" label="运营修改">
              <template slot-scope="scope">
                <el-input v-if="virtuals[scope.$index] !== '-'" v-model="virtuals[scope.$index]" class="w-60"
                  type="number" min="0"></el-input>
                <!--                                      @keyup.enter.native="$event.target.blur"-->
                <!--                                      @blur="updateVirtual"-->
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column prop="display" label="显示数据">
              <template slot-scope="scope">
                {{scope.$index > 1 ? scope.row.origin : (Number(virtuals[scope.$index]) + scope.row.origin)}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <div style="margin-top: 20px" class="list-content" v-if="detail">
      <el-card class="no-border" shadow="never">
        <div class="card-title-no-border">
          <label>所有评论</label>
        </div>
        <el-divider></el-divider>
        <div class="card-content">
          <el-table :data="comments.data" stripe class="thead-light">
            <el-table-column prop="" label="头像" width="80">
              <template slot-scope="scope">
                <el-image class="tabel-avatar" :src="scope.row.avatar ? scope.row.avatar : ''" fit="cover">
                  <img slot="error" :src="require('@/modules/admin/assets/images/avatar.png')" width="100%" />
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="" label="会员名称" min-width="100px" show-overflow-tooltip>
              <template slot-scope="scope">{{scope.row.nickname ? scope.row.nickname: 'admin'}}</template>
            </el-table-column>
            <el-table-column prop="" label="架构/会内职务" min-width="150" show-overflow-tooltip>
              <template
                slot-scope="scope">{{scope.row.title_name ? (scope.row.title_name ? (scope.row.title_name ? scope.row.title_name : '-') : '-') : '-'}}</template>
            </el-table-column>
            <el-table-column prop="content" label="评论内容" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="likes_count" label="点赞数"></el-table-column>
            <el-table-column prop="replies_count" label="回复数"></el-table-column>
            <!--                        <el-table-column prop="" label="举报数"></el-table-column>-->
            <el-table-column label="上级评论内容" width="150" show-overflow-tooltip>
              <template
                slot-scope="scope">{{scope.row.replies_pid && scope.row.replies_pid[0] ? scope.row.replies_pid[0].content: '-'}}</template>
            </el-table-column>
            <el-table-column prop="create_time" label="评论时间" width="200" sortable="custom"></el-table-column>
            <el-table-column prop="" label="操作" width="60" fixed="right">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteComment(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <Pagination :total="total" :page="filterForm.page" :change="changPage"></Pagination>
    </div>
    <fixed-action-bar>
      <el-button type="primary" size="medium" @click="save">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">返回</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import MediaWall from "@/modules/common/components/MediaWall.vue";

import {
  getUpdaDetail,
  getUpdaContentAll,
  getSave,
  getUpdateVirtual,
} from "../../api/experience-list";
import Pagination from "@/base/components/Default/Pagination";
import { softDelete } from "../../api/experience-comments";
import FixedActionBar from "../../../../base/layout/FixedActionBar";
export default {
  components: {
    FixedActionBar,
    MediaWall,
    Pagination,
  },
  data() {
    return {
      filterForm: {
        page_size: 15, //每页多少条数据
        page: 1,
      },
      id: 0,
      detail: null,
      tableData: [],
      comments: [],
      // 点赞、评论量
      virtuals: [0, 0],
      showTextarea: true,
      loading: false,
      total: 0,
      page: 1,
    };
  },
  created() {
    this.id = this.$route.params.id;
    getUpdaDetail({ id: this.id }).then((res) => {
      this.detail = res.data;
      this.initData();
    });
    this.UpdaContentAll();
  },
  methods: {
    changPage(data) {
      this.filterForm.page = data.page;
      this.filterForm.page_size = data.page_size;
      this.UpdaContentAll();
    },
    UpdaContentAll() {
      this.loading = true;
      getUpdaContentAll({ id: this.id, page: 1 }).then((res) => {
        this.comments = res.data;
        this.loading = false;
      });
    },
    initData() {
      this.content = this.detail.content;
      this.mediaList = this.detail.res || [];
      this.max = 9 - this.mediaList.length;
      this.tableData = [];
      this.tableData.push(
        {
          title: "点赞量",
          origin: this.detail.like_count,
          virtual: this.detail.virtual_like_num,
          display: this.detail.like_count + this.detail.virtual_like_num,
        },
        {
          title: "评论量",
          origin: this.detail.comments_count,
          virtual: this.detail.virtual_comment_num,
          display: this.detail.comments_count + this.detail.virtual_comment_num,
        }
      );
      // 虚拟点赞
      this.virtuals[0] = this.detail.virtual_like_num;
      // 虚拟评论
      this.virtuals[1] = this.detail.virtual_comment_num;
    },
    deleteComment(e) {
      softDelete({ id: e }).then((res) => {
        this.UpdaContentAll();
        this.$message.success("删除成功");
      });
    },
    cancelConfig() {
      window.history.go(-1);
    },
    save() {
      let e = {
        id: this.id,
        res: this.detail.res,
        content: this.detail.content,
      };
      let i = {
        id: this.id,
        virtual_comment_num: this.virtuals[1],
        virtual_like_num: this.virtuals[0],
      };
      getUpdateVirtual(i).then((res) => {});
      getSave(e).then((res) => {
        this.$message.success("保存成功");
        window.history.go(-1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
  min-height: calc(100vh - 190px);
}
.list-content {
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .row-bg {
    padding: 10px 0;
  }
  .card-table {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  .table-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 14px;

    padding: 15px 24px;
    background: rgba(250, 250, 250, 1);
    border-bottom: 1px solid #e9e9e9;
  }
  .table-content {
    padding: 16px 24px 20px 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 22px;
    position: relative;
  }
  .total-count.text-right {
    color: #717171;
    position: absolute;
    right: 32px;
    bottom: 8px;
    line-height: 50px;
  }
  ::v-deep.el-textarea__inner {
    height: 150px;
  }
}
</style>
